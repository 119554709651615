@import 'sass/variables';

html,
body {
    font-family: MyriadPro, Arial, Helvetica, sans-serif;
    font-weight: 300;
}

body.login div#login h1 a {
    width: 80px;
    height: 80px;
    background: url('/out/kastnergigasport/img/' + $login-icon-filename) 0 0 $primary-color;
    background-size: cover;
}

body.login div#login form#loginform p {
    margin-left: 3px;
}

body.login div#login form#loginform input[type='text'],
body.login div#login form#loginform input[type='password'] {
    border: 1px solid #bbbbbd;
    color: #bbbbbd;
    outline: none;
    transition: border-color 400ms ease;
    font-size: 1rem;
    height: 32px;
    padding: .5rem .9rem;
    background-color: white;
    text-align: left;
    border-radius: 3px;
    margin-left: -3px;
}

body.login div#login form#loginform input[type='text']:hover,
body.login div#login form#loginform input[type='text']:focus,
body.login div#login form#loginform input[type='password']:hover,
body.login div#login form#loginform input[type='password']:focus,
body.login div#login form#loginform input[type='checkbox']:hover,
body.login div#login form#loginform input[type='checkbox']:focus {
    border-color: #878789;
    color: #4a4a4c;
}

input#wp-submit {
    background-color: $primary-color !important;
    font-weight: 600 !important;

    transition: color .4s ease, background-color .4s ease, opacity .4s ease;
    border: 0;
    box-shadow: none;
    text-shadow: none;
}

input#wp-submit:hover {
    background-color: $grey-color !important;
}

p#nav,
p#backtoblog {
    background-color: white;
    margin-top: 0 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .13);
    padding: 5px 0 5px 5px !important;
    font-size: 14px !important;
    max-width: 315px;
}

p#nav {
    text-align: center;
    border-top: 1px solid #bbbbbd;
}

p#nav a {
    font-weight: 600 !important;
    transition: color 400ms ease;
}

.login #backtoblog a:hover,
.login #nav a:hover,
.login h1 a:hover {
    color: $highlight-color !important;
}

form#loginform {
    padding-bottom: 20px !important;
}
